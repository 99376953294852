<template>
  <div>
    <div>
      <div
        class="weather-panel"
        :style="
          liningStyles +
          `margin-left:${
            landing.branding.lining_margin ? '10px' : '0'
          };margin-right:${landing.branding.lining_margin ? '10px' : '0'};margin-top:${
                landing.advanced.global.active_header_inside ||
                landing.advanced.global.active_logo_inside
                  ? '20px'
                  : `${+landing.branding.topMenu.styles.size * 2.5}px`
              };min-height:300px;`
        "
      >
        <div v-html="content.top_content" style="padding:0 30px;"></div>

        <div
          style="display: flex; flex-direction: column"
          :style="`color:${content.textColor};font-family:${content.font};`"
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            "
          >
            <img
              v-if="content.icon_type === 0"
              :style="`height:${content.mainIconSize}px;width:${content.mainIconSize}px;`"
              :src="`https://openweathermap.org/img/wn/${landing.advanced.global.weather.week.daily[0].weather[0].icon}@4x.png`"
              alt=""
            />
            <i
              v-if="content.icon_type === 1"
              :style="`color:${content.iconColor};font-size:${content.mainIconSize}px;height:${content.mainIconSize}px;width:${content.mainIconSize}px;`"
              class="owf"
              :class="`owf-${landing.advanced.global.weather.week.daily[0].weather[0].id}`"
            ></i>
            <i
              v-if="content.icon_type === 2"
              :style="`color:${content.iconColor};font-size:${content.mainIconSize}px;height:${content.mainIconSize}px;width:${content.mainIconSize}px;`"
              class="wu wu-black wu-64"
              :class="`wu-${landing.advanced.global.weather.week.daily[0].weather[0].main.toLowerCase()}`"
            ></i>

            <p class="h3" style="display: flex; align-items: center">
              {{
                landing.advanced.global.weather.week.daily[0].weather[0].main
              }}
            </p>
          </div>
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div
              style="
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <h2 style="display: flex; flex-direction: column">
                {{ location }}<br /><small>{{
                  todayFormat(landing.advanced.global.weather.week.daily[0].dt)
                }}</small
                ><small v-if="landing.advanced.global.show_time">{{
                  time
                }}</small>
              </h2>
            </div>
            <div class="text-center" style="width: 50%">
              <div class="h1 temperature" style="font-size: 40px">
                <span
                  >{{
                    kelvinToCer(
                      landing.advanced.global.weather.week.daily[0].temp.day
                    )
                  }}°</span
                >
              </div>
            </div>
          </div>
        </div>
        <div
          :style="`color:${content.textColor};font-family:${content.font};`"
          style="
            width: 100%;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            padding: 15px;
            height: 300px;
            padding-top: 50px;
          "
        >
          <div
            v-for="(day, dayIndex) in arr"
            :key="dayIndex"
            style="
              width: 33%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            "
          >
            <h5 class="h5" v-if="dayIndex === 0">Today</h5>
            <h5 class="h5" v-else-if="dayIndex === 1">Tommorow</h5>
            <h5 class="h5" v-else>{{ convertDay(day.dt) }}</h5>
            <div>
              <img
                v-if="content.icon_type === 0"
                :style="`height:${content.weekIconSize}px;width:${content.weekIconSize}px;`"
                :src="`https://openweathermap.org/img/wn/${day.weather[0].icon}@4x.png`"
                alt=""
              />
              <i
                v-if="content.icon_type === 1"
                style=""
                :style="`color:${content.iconColor};height: ${content.weekIconSize}px;width: ${content.weekIconSize}px;font-size:${content.weekIconSize}px;`"
                class="owf"
                :class="`owf-${day.weather[0].id}`"
              ></i>
              <i
                v-if="content.icon_type === 2"
                style=""
                :style="`color:${content.iconColor};height: ${content.weekIconSize}px;width: ${content.weekIconSize}px;font-size:${content.weekIconSize}px;`"
                class="wu wu-black wu-64"
                :class="`wu-${day.weather[0].main.toLowerCase()}`"
              ></i>
            </div>
            <div>{{ kelvinToCer(day.temp.day) }}°</div>
          </div>
        </div>
          <div v-html="content.bottom_content" style="padding:0 30px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import "@/assets/icons/weather/wu/wu-icons-style.min.css";
export default {
  name: "typeWeatherPreview",
  props: ["content"],
  computed: {
    ...mapState(["landing"]),
    ...mapState('advanced',['location']),
    arr() {
      return this.landing.advanced.global.weather.week.daily.slice(0, 6);
    },

    liningStyles() {
      if (this.landing.branding.bg_lining_img) {
        return `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        return `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
    },
  },
  data() {
    return {
      time: "",
    };
  },
  methods: {
    convertDay(unix) {
      return moment.unix(unix).format("ddd");
    },
    todayFormat(unix) {
      return moment.unix(unix).format("ddd DD, GGGG");
    },
    kelvinToCer(temp) {
      return temp.toFixed(0);
    },
    updateTime() {
      let date = moment().tz(this.landing.advanced.global.timezone.name);
      this.time = date.format("HH:mm");
    },
  },
  mounted() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
